import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import watermark from "../images/sammons.png"

const Portfolio = ({ data: { allContentfulPortfolio } }) => (
  <Layout>
    <SEO title="Portfolio" />
    <div className="portfolio page-container">
      <div className="container-summary">
        {renderRichText(allContentfulPortfolio.edges[0].node.portfolioHeader, {
          renderNode: {
            [BLOCKS.HEADING_2]: (node, children) => (
              <h2 className="portfolio-header">{children}</h2>
            ),
            [BLOCKS.PARAGRAPH]: (node, children) => (
              <p className="section-summary align-center">{children}</p>
            ),
          },
        })}
      </div>
      <div className="portfolio-card-container">
        {allContentfulPortfolio.edges[0].node.portfolioEntries.references.map(
          node => (
            <div key={node.id} className="portfolio-entry shadow">
              <img
                src={node.logo.file.url}
                alt={node.logo.file.fileName}
                className="pe-image"
              />
              <div className="pe-hover">
                <p className="bio">{node.summary.summary}</p>
                <div className="person-container">
                  {/* <img className="person-image" src={node.presidentImage.file.url} alt={node.presidentImage.file.fileName}/> */}
                  <h5>
                    {node.presidentName}, {node.presidentTitle}
                  </h5>
                </div>
              </div>
              <div className="learn-more-button-container">
                {node.buttons.map(button => (
                  <a
                    key={button}
                    className="learn-more-button"
                    href={node.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {button}
                  </a>
                ))}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  </Layout>
)

export default Portfolio

export const pageQuery = graphql`
  {
    allContentfulPortfolio {
      edges {
        node {
          slug
          title
          portfolioHeader {
            raw
          }
          portfolioEntries {
            references {
              header
              summary {
                summary
              }
              logo {
                file {
                  fileName
                  url
                }
              }
              url
              id
              buttons
              presidentImage {
                file {
                  fileName
                  url
                }
              }
              presidentTitle
              presidentName
            }
          }
        }
      }
    }
  }
`
